@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


html{
  scroll-behavior: smooth;
}


.policy-part-container{

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height:100%;
  align-items: flex-start;
  margin-left: 2vw;
  
}

  /* Desktop Styles (1024px and above) */
  @media (min-width: 1024px) {
    .policy-container{

        display: flex;
        width: 95%;
        height: 100%;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
    
    }
    .policy-part-container h1{

      font-size: 7vh;
      margin-bottom: 3vh;
    
    }
    
    .policy-title {
      font-size: 1.3rem;
      color: #333;
      margin-bottom: 16px;
  }
  
  .policy-description {
      font-size: 1rem;
      line-height: 1.6;
      color: #555;
      margin-bottom: 16px;
  }
  
  .policy-description .policy-a-link {
      color: #0056b3;
      text-decoration: none;
      
  }
  
  .policy-description .policy-a-link:hover {
      text-decoration: underline;
  }
  
  .policy-section-title {
      font-size: 1.9rem;
      color: #120621;
      margin-top: 24px;
      margin-bottom: 8px;
      align-self: flex-start;
      transition: .7s ease-in;  
  }
  .policy-section-title:hover {
    text-decoration: underline;
    
  }

  .policy-section-title a {
      text-decoration: none;
      color: #120621;
      font-size: 4vh;
  }
  .policy-part-container .in-page-links li{

    margin: 2vh;
    margin-left: 5vw;
  }
  .policy-part-container .in-page-links li a{
    
      color: #0056b3;
      font-size: 2.5vh;

  }
  .policy-part-container ol li{
    margin: 2vh;
    margin-bottom: 3vh;
    font-size: 2.5vh;
    color: #555;
  }
  
  .policy-section-description {
      font-size: 1rem;
      line-height: 1.6;
      color: #555;
      margin-bottom: 16px;
  }
    
  }
  
  
 /* Tablet Styles (768px to 1023px) */
 @media (min-width: 768px) and (max-width: 1023px) {
  .policy-container{

    display: flex;
    width: 95%;
    height: 100%;
    flex-direction: column;

}
.policy-part-container{

  margin-left: 10vw;
}
.policy-part-container h1{

font-size: 7vh;
margin-bottom: 3vh;

}

.policy-title {
font-size: 1.3rem;
color: #333;
margin-bottom: 16px;
}

.policy-description {
font-size: 1rem;
line-height: 1.6;
color: #555;
margin-bottom: 16px;
}

.policy-description .policy-a-link {
color: #0056b3;
text-decoration: none;

}

.policy-description .policy-a-link:hover {
text-decoration: underline;
}

.policy-section-title {
font-size: 1.9rem;
color: #120621;
margin-top: 24px;
margin-bottom: 8px;
align-self: flex-start;
transition: .7s ease-in;  
}
.policy-section-title:hover {
text-decoration: underline;

}

.policy-section-title a {
text-decoration: none;
color: #120621;
font-size: 4vh;
}
.policy-part-container .in-page-links li{

margin: 2vh;
margin-left: 5vw;
}
.policy-part-container .in-page-links li a{

color: #0056b3;
font-size: 2.5vh;

}
.policy-part-container ol li{
margin: 2vh;
margin-bottom: 3vh;
font-size: 2.5vh;
color: #555;
}

.policy-section-description {
font-size: 1rem;
line-height: 1.6;
color: #555;
margin-bottom: 16px;
}
}



  /* Mobile Styles (Below 768px) */
  @media (max-width: 767px) {
    .policy-container{

        display: flex;
        width: 95%;
        height: 100%;
        flex-direction: column;
    
    }
    /* .policy-part-container{

      margin-left: 10vw;
    } */
  .policy-part-container h1{
    
    font-size: 7vh;
    margin-bottom: 3vh;
  
  }
  
  .policy-title {
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 16px;
}

.policy-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 16px;
}

.policy-description .policy-a-link {
    color: #0056b3;
    text-decoration: none;
    
}

.policy-description .policy-a-link:hover {
    text-decoration: underline;
}

.policy-section-title {
    font-size: 1.9rem;
    color: #120621;
    margin-top: 24px;
    margin-bottom: 8px;
    align-self: flex-start;
    transition: .7s ease-in;  
}
.policy-section-title:hover {
  text-decoration: underline;
  
}

.policy-section-title a {
    text-decoration: none;
    color: #120621;
    font-size: 4vh;
}
.policy-part-container .in-page-links li{

  margin: 2vh;
  margin-left: 5vw;
}
.policy-part-container .in-page-links li a{
  
    color: #0056b3;
    font-size: 2.5vh;

}
.policy-part-container ol li{
  margin: 2vh;
  margin-bottom: 3vh;
  font-size: 2.5vh;
  color: #555;
}

.policy-section-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 16px;
}
  }
  