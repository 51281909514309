.card-link{

    text-decoration: none;
    width: auto;
    height: auto;
}
.hover-card {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: auto;
    aspect-ratio: 4/5;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hover-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .hover-card:hover .hover-card__image {
    transform: scale(1.1);
  }
  
  .hover-card__overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7) 0%,
      transparent 50%,
      transparent 100%
    );
    transition: background 0.3s ease;
  }
  
  .hover-card:hover .hover-card__overlay {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      transparent 100%
    );
  }
  
  .hover-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }
  
  .hover-card__heading {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
  }
  
  .hover-card__subheading {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.875rem;
    margin: 0;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
  }
  
  .hover-card:hover .hover-card__subheading {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .hover-card {
      max-width: 100%;
    }
  
    .hover-card__heading {
      font-size: 1.25rem;
    }
  
    .hover-card__subheading {
      font-size: 0.75rem;
    }
  }
  
  /* For smaller devices where hover might not be available */
  @media (hover: none) {
    .hover-card__subheading {
      opacity: 1;
      transform: translateY(0);
    }
  
    .hover-card__overlay {
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.4) 50%,
        transparent 100%
      );
    }
  }
  /* CardCollection.css */
.card-collection {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .card-collection__title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
    color: #333;
  }
  
  .card-collection__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    width: 100%;
  }
  
  .card-collection__item {
    display: flex;
    justify-content: center;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .card-collection {
      padding: 1rem;
    }
  
    .card-collection__title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  
    .card-collection__grid {
      gap: 1.5rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .card-collection__title {
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
    }
  
    .card-collection__grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  /* CardDetail.css */
.card-detail {
    min-height: 100vh;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .card-detail__back-button {
    background: none;
    border: none;
    padding: 1rem;
    font-size: 1.1rem;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .card-detail__back-button:hover {
    color: #666;
  }
  
  .card-detail__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
  }
  
  .card-detail__image-container {
    position: relative;
    width: 100%;
    aspect-ratio: 4/5;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .card-detail__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-detail__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
  }
  
  .card-detail__heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .card-detail__subheading {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .card-detail__description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 968px) {
    .card-detail__content {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .card-detail__text {
      padding: 1rem 0;
    }
  
    .card-detail__heading {
      font-size: 2rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .card-detail {
      padding: 1rem;
    }
  
    .card-detail__heading {
      font-size: 1.75rem;
    }
  
    .card-detail__subheading {
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
    }
  
    .card-detail__description {
      font-size: 1rem;
    }
  }