@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Overpass:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    --bg: #F3F3F3;
    --bg-footer: #120621;

    --bg-button: #120621;

    --fg: #120621;
    --fg-button: #CEB345;
    --link: #5A378A;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
#root{
  width: 100%;
}
@font-face{
    font-family: 'Alegreya';
    src: url('../fonts/alegreya.woff2') format('woff2');
    font-style: normal;
}
@font-face{
    font-family: 'Overpass';
    src: url('../fonts/overpass.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

/* Desktop Styles (Large Screens) */
@media (min-width: 1024px) {
    .logo{
        display: flex;
        width: 100vw;
        height: 40vh;
        justify-content: space-around;
        margin: 3vh 0vh;
        margin-right: 0vh;
       }
       .logo .logo-title{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20vh;
        /* width */
       }
       .logo-image{
        display: flex;
        flex-direction: column;
        height: 35vh;
        justify-content: space-between;
        align-items: center;
        margin: 5vw;
       }
       .logo-image h1{
        font-size: 24vh;
        color: var(--bg-button);
        font-family: "Alegreya Sans", sans-serif;
        font-weight: 700;
        
       }
       .logo-image p{
        font-size: 3.5vh;
        color: var(--link) ;
        font-weight: 400;
    
       }
       .logo-title button{
        outline: none;
        width: 65%;
        align-self: center;
        padding: 3vh 2vh;
        border-radius: 1vh;
        border: none;
        background: #120621;
        color: #F3F3F3;
    
       }
        .signup-home-btn{
        width: 70%;
        height: 7vh;
        color:  #a18c36    ;
        text-decoration: none;
        font-size: 2.5vh;
        font-weight: 600;
    
       }
       .logo .image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       }
       .logo .image p{
        margin-top: 1vh;
        font-size: 1.5vh;
        font-weight: 600;
       }
       .logo .image img{
        width: 18vw;
        height: 56vh;
        margin-top: 27vh;
        border-radius:1vh ;
        margin-right: 4vw;
        margin-left: 4vw;
        aspect-ratio: 3/4;
       }
       .element-container{

        display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;             
      max-width: 100vw;
      margin-top: 34vh;
       }
       .group-conatiner-ele{
        display: flex;
      flex-direction: row;
      width: 95%;               
      margin-bottom: 7vh;
      justify-content: space-around;
      align-items: center;
      margin-top: 20vh;
       }
       .group-conatiner-ele:nth-child(even){
        flex-direction: row-reverse;
       }
       .image-group{
        display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);  
      gap: 10px;                            
      width: 20%;                          
      max-width: 30vw;                     
          
       }
       .image-group .image-box {
        width: 100%;  
        height: auto;    
        border-radius: 1vh;
    
      }
      .description {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: relative;
        width: 100%;             
        max-width: 39vw;
        min-height: 44vh;    
        padding: 4rem;           
        color: white;           
        background-size: cover;  
        background-position: center;
        border-radius: 8px;      
      }
      
      .description .heading,
      .description .desc {

        z-index: 1;
      }
      .description .heading{
        text-align: center;
        font-size: 4vw;
        color: #F3F3F3;
      }
      .description .desc {
        font-size: 1.2vw;
        color: #F3F3F3;
      }
     .news-image{
        width: 10vw;
        height: 30vh;
        border-radius: 1vh;
        
     }
      .svgimage{
        width: 100%;             
        max-width: 71vw;
        height: 48vh;

      }

    
      .servives-home{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 95vw;
        height: 50vh;
        margin-top: 7vh;
        margin-bottom: 7vh;
      }
      .service-header-h{
        font-size: 10vh;
        color: var(--bg-footer);
      }
      /* responsiveness needed */
      .service-content-container{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-top: 10vh;
        min-width: 27vw;
        margin-bottom: 10vh;
      }
      .content-service{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 6vh;
        height: 8vh;
        margin-top: 3vh;
        width: 100%;
        padding-left: 7vw;
      }
      .content-header-service{
        font-size: 2.8vh;
        align-self: flex-start;
        color: var(--link);
    
      }
      .content-desc-service{
        font-size: 1.3vw;
        color: var(--link);
        margin-top: 3vh;
      }
    


}

/* Tablet Styles (Medium Screens) */
@media (min-width: 768px) and (max-width: 1024px) {
    .logo{
        display: flex;
        width: 100%;
        height: 40vh;
        justify-content: space-around;
        margin: 3vh 2vh;
        margin-right: 3vh;
       }
       .logo .logo-title{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20vh;
        /* width */
       }
       .logo-image{
        display: flex;
        flex-direction: column;
        height: 25vh;
        justify-content: space-between;
        align-items: center;
        margin: 5vw;
       }
       .logo-image h1{
        font-size: 14vh;
        color: var(--bg-button);
        font-family: "Alegreya Sans", sans-serif;
        font-weight: 700;
        
       }
       .logo-image p{
        font-size: 1.5vh;
        color: var(--link) ;
        font-weight: 400;
    
       }
       .logo-title button{
        outline: none;
        width: 65%;
        align-self: center;
        padding: 2vh 2vh;
        border-radius: 1vh;
        border: none;
        background: #120621;
        color: #F3F3F3;
    
       }
        .signup-home-btn{
        width: 70%;
        height: 7vh;
        color:  #a18c36    ;
        text-decoration: none;
        font-size: 2.3vh;
        font-weight: 600;
    
       }
       .logo .image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       }
       .logo .image p{
        margin-top: 1vh;
        font-size: 1.5vh;
        font-weight: 600;
       }
       .logo .image img{
        width: 31vw;
        height: 43vh;
        margin-top: 12vh;
        border-radius:1vh ;
        margin-right: 4vw;
        margin-left: 4vw;
        aspect-ratio: 3/4;
       }


       .element-container{

        display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;             
      max-width: 100vw;
       }
       .group-conatiner-ele{
        display: flex;
      flex-direction: row;
      width: 100%;               
      margin-bottom: 5vh;
      margin-top: 10vh;
      justify-content: space-around;
      align-items: center;
       }
       .group-conatiner-ele:nth-child(even){
        flex-direction: row-reverse;
       }
       .image-group{
        display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);  
      
      gap: 10px;                            
      width: 20%;                          
      max-width: 400px;                     
        
       }
       .image-group .image-box {
        width: 100%;  
        height: auto;    
        border-radius: 1vh;
    
      }
      .description {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: relative;
        width: 100%;             
        max-width: 42vw;
        padding-top: 25vh;
        padding-bottom: 25vh;
        min-height: 17vh;    
        padding: 2rem;           
        color: white;           
        background-size: cover;  
        background-position: center;
        border-radius: 8px;      
      }
      .description .heading,
      .description .desc {

        z-index: 1;
      }
      .description .heading{
        text-align: center;
        font-size: 4vw;
        color: #F3F3F3;
      }
      .description .desc {
        font-size: 1.2vw;
        color: #F3F3F3;
      }
     .news-image{
      width: 13vw;
      height: 28vh;
        border-radius: 1vh;
        
     }
      .svgimage{
        width: 100%;             
        max-width: 70vw;         
        height: 35vh;
      }


      .servives-home{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 95vw;
      }
      .service-header-h{
        font-size: 4vh;
        color: var(--bg-footer);
      }
      /* responsiveness needed */
      .service-content-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-top: 5vh;
        min-height: 50vh;
      }
      .content-service{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 6vh;
        height: 8vh;
        margin-top: 3vh;
        width: 100%;
        padding-left: 7vw;
      }
      .content-header-service{
        font-size: 2.8vh;
        align-self: flex-start;
        color: var(--link);
    
      }
      .content-desc-service{
        font-size: 2.3vw;
        color: var(--link);
        margin-top: 1vh;
      }
    
}

/* Mobile Styles (Small Screens) */
@media (max-width: 768px) {
   .logo{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90vw;
    margin-right: 5vw;
    /* margin-top: 5vh; */
    margin-bottom: 10vh;
   }
   .logo .logo-title{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
    width:60vw;
   }
   .logo-image{
    display: flex;
    flex-direction: column;
    height: 25vh;
    justify-content: space-between;
    align-items: center;
    margin: 5vw;
   }
   .logo-image h1{
    font-size: 8vh;
    color: var(--bg-button);
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 700;
    
   }
   .logo-image p{
    font-size: 1.2vh;
    color: var(--link) ;
    font-weight: 400;

   }
   .logo-title button{
    outline: none;
    width: 75%;
    align-self: center;
    padding: 1.2vh 1.2vh;
    border-radius: 1vh;
    border: none;
    background: #120621;
    color: #F3F3F3;

   }
    .signup-home-btn{
    width: 70%;
    height: 7vh;
    color:  #a18c36    ;
    text-decoration: none;
    font-size: 2vh;
    font-weight: 600;

   }
   .logo .image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
   .logo .image p{
    margin-top: 1vh;
    font-size: 1vh;
    font-weight: 600;
   }
   .logo .image img{
    width: 30vw;
    height: 20vh;
    border-radius:1vh ;
    margin-right: 4vw;
    margin-left: 4vw;
   }

   .element-container{

    display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;             
  max-width: 100vw;
  min-height: 50vh;
   }
   .group-conatiner-ele{
    display: flex;
  flex-direction: row;
  width: 90%;               
  margin-bottom: 5vh;
  justify-content: space-between;
  align-items: center;
  

   }
   .group-conatiner-ele:nth-child(even){
    flex-direction: row-reverse;
   }
   .image-group{
    display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);  
  gap: 10px;                            
  width: 27%;                          
  max-width: 400px;                     
  margin: 0 auto;    
  
   }
   .image-group .image-box {
    width: 100%;  
    height: auto;    
    border-radius: 1vh;

  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 100%;             
    max-width: 48vw;
    padding-top: 25vh;
    padding-bottom: 25vh;
    min-height: 17vh;    
    padding: 2rem;           
    color: white;           
    background-size: cover;  
    background-position: center;
    border-radius: 8px;      
  }
  
  .description .heading,
  .description .desc {

    z-index: 1;
  }
  .description .heading{
    text-align: center;
    font-size: 5vw;
    color: #F3F3F3;
  }
  .description .desc {
    font-size: 1.3vw;
    color: #F3F3F3;
  }
 .news-image{
  width: 15vw;
  height: 16vh;
    border-radius: 1vh;
    
 }
  .svgimage{
    width: 100%;             
    max-width: 70vw;         
    height: 20vh;
  }
  .btn{
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .servives-home{
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 95vw;
  }
  .service-header-h{
    font-size: 4vh;
    color: var(--bg-footer);
    text-align: center;
  }
  /* responsiveness needed */
  .service-content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 5vh;
    min-height: 50vh;
  }
  .content-service{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 6vh;
    height: 8vh;
    margin-top: 3vh;
    width: 100%;
    padding-left: 7vw;
  }
  .content-header-service{
    font-size: 2.8vh;
    align-self: flex-start;
    color: var(--link);

  }
  .content-desc-service{
    font-size: 2.3vw;
    color: var(--link);
    margin-top: 1vh;
  }

}
