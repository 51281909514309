/* Footer Styles */

footer {
    background-color: #120621;
    color: #f4f4f4;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 10vh;
    width: 100vw;
    justify-content: space-around;
    height: 60vh;
  }
  
  /* General styling */
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    flex-direction: row;
    height:26vh;
  }
  
  .scitome {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    flex-direction: column;
  }
  
  .scitome .logo {
    width: 12vh;
    height: 12vh;
    transition: transform 0.3s ease;
    align-self: center;
  }
  
  .scitome .logo:hover {
    transform: scale(1.1);
  }
  
  .scitome-logo {
    margin-left: 15px;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .scitome-logo:hover {
    color: #CEB345;
    transform: translateY(-3px);
  }
  #graph{
    width:16vh;
    height: 16vh;
  }
  .footer-title {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 1vh;
  }
  
  .footer-text {
    font-size: 2vh;
    color: #CEB345;
  }
  
  /* Social media icons styling */
  .socials{
    display: flex;
    width: 80vw;
    height: 10vh;
    align-items: center;
    justify-content: center;
  }
  .socials a {
    margin: 0 2vh;
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .socials .a-image {
    width: 24px;
    height: 24px;
  }
  
  .socials a:hover .a-image {
    transform: scale(1.1);
    filter: brightness(1.5);
  }
  
  /* Footer links styling */
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    text-align: center;
    width: 90%;
    height: 35vh;
    
  }
  
  .footer-links .link-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 3vh;
    width: 20vw;
    height: 19vh;
  }
  
  .footer-links h4 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #CEB345;
  }
  
  .a-link {
    color: #f4f4f4;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
   
  }
  
  .a-link:hover {
    color: #CEB345;
  }
  .footer .scitome{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20vh;
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  
    .footer-links {
      flex-direction: column;
      gap: 20px;
      width: 95vw;
      
    }
    .scitome{
        align-items: center;
        justify-content: center;
        width: 85%;
    }
  }
  
  @media (max-width: 480px) {
    .footer-title {
      font-size: 1.3rem;
    }
  
    .footer-links div {
      width: 100%;
    }
    .a-link{
    font-size: 1.5vh;    
    }
  }
  