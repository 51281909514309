/* Container */
.ja-job-alerts-container {
    font-family: Arial, sans-serif;
    color: #4b0082; /* Purple color */
    max-width: 60vw;
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
  }
  
  /* Header */
  .ja-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    width: 40vw;
  }
  
  .ja-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 10px 10px 0;
  }
  
  .ja-notification-button {
    background-color: #dab3f9;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
    color: #4b0082;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
  }
  
  .ja-notification-button .ja-bell-icon {
    font-size: 18px;
  }
  
  /* Job Section */
  .ja-job-section {
    margin-bottom: 20px;
  }
  
  .ja-job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .ja-job-title {
    font-size: 18px;
    margin: 0;
  }
  
  .ja-dropdown-icon {
    font-size: 18px;
    color: #4b0082;
  }
  
  /* Job Content */
  .ja-job-content {
    background-color: #f3e8fe; /* Light purple */
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .ja-no-jobs {
    margin: 0;
    font-size: 14px;
    font-style: italic;
    color: #4b0082;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .ja-job-alerts-container {
      padding: 15px;
    }
  
    .ja-header {
      justify-content: center;
    }
  
    .ja-title {
      font-size: 20px;
      text-align: center;
    }
  
    .ja-notification-button {
      font-size: 12px;
      padding: 8px 16px;
    }
  
    .ja-job-title {
      font-size: 16px;
    }
  
    .ja-dropdown-icon {
      font-size: 16px;
    }
  
    .ja-job-content {
      padding: 10px;
    }
  
    .ja-no-jobs {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .ja-notification-button {
      padding: 8px 10px;
      flex-wrap: wrap;
      gap: 4px;
      text-align: center;
    }
  
    .ja-header {
      flex-direction: column;
      gap: 10px;
    }
  
    .ja-title {
      font-size: 18px;
    }
  }
  