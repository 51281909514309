.topics-container {
    padding: 20px;
    max-width: 100vw;
    padding:10vh;
    margin: 0 auto;
    height: 100vh;
    background: #120621;
  }
  
  .filter-container {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    padding: 10px 0;
    margin-bottom: 20px;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .filter-container::-webkit-scrollbar {
    display: none;
  }
  
  .filter-box {
    min-width: 120px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
    flex-shrink: 0;
  }
  
  .filter-box:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .filter-box.active {
    background: #f0f7ff;
    border-color: #2196f3;
  }
  
  .filter-box img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .topics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .topic-card {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .topic-image {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .topic-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .topic-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .voting-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .vote-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    color: #666;
  }
  
  .vote-button:hover {
    color: #2196f3;
  }
  
  .vote-count {
    font-weight: 500;
    min-width: 30px;
    text-align: center;
  }
  
  .topic-description {
    color: #666;
    line-height: 1.5;
    margin: 0;
  }
  
  .vote-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    color: #666;
    transition: color 0.2s ease;
  }
  
  .vote-button:hover {
    color: #2196f3;
  }
  
  .vote-button.voted {
    color: #120621;
  }
  
  .vote-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }