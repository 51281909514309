/* Base Styles */
.about-container {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: auto;
  font-family: "Open Sans", sans-serif;
  line-height: 1.8;
  color: #333;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: .5rem;
  color: #004aad;
}
.about-title-desc{
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #004aad;
}
.about-subtitle {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #0077b6;
}

.about-text {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.about-section {
  margin-bottom: 3rem;
  padding: 2rem;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Animation Enhancements */
.about-container .about-title,
.about-container .about-subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.about-container .about-section {
  transition: all 0.3s ease-in-out;
}

.about-container .about-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
