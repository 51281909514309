.acc-account-details-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #faf7f7;
    align-items: center;
    padding-top: 5vh;
  }
  
  .acc-nav-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #faf7f7;
    padding: 1.2vh 0;
    min-width: 70vw;
    
  }
  
  .acc-nav-item {
    padding: 2vh 4vw; 
    font-size: 2vh; 
    font-family: Arial, sans-serif;
    color: #200f36; 
    border: 0.3vh solid #200f36; 
    border-radius: 5vw; 
    background-color: transparent; 
    text-transform: uppercase; 
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .acc-nav-item:hover{
    background-color: #200f36; 
  color: white;
  }
  .acc-account-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2vh 2vw;
  }
  
  .acc-account-title {
    font-size: 1.8vw;
    font-weight: bold;
    margin-bottom: 2vh;
    width: 80vw;
    background-color: #200f36;
    color: #faf7f7;
    text-align: center;
  }
  
  .acc-submissions-wrapper,
  .acc-jobs-wrapper,
  .acc-bookmarks-wrapper {
    background-color: #e6e6e6;
    padding: 2vh 2vw;
    margin-bottom: 2vh;
    width: 80vw;
    border-radius: 1vh;
    transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .acc-submissions-wrapper{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 20vh;
  }
  .acc-section-title {
    font-size: 1.4vw;
    font-weight: bold;
    margin-bottom: 1vh;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .acc-submission-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;

  }
  
  .acc-submission-title {
    font-size: 1.2vw;
  }
  
  .acc-submission-progress {
    height: 3vh;
    background-color: green;
    width: 35vw;
    border-radius: 4vw;
    transition: width 0.5s ease;
  }
  
  .acc-jobs-content,
  .acc-bookmarks-content {
    padding-top: 1vh;
    transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }