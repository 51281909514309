* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .cards-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    max-width: 60vw;
    margin: 0 auto;
    
  }

  .card {
    background-color: white;
    border-radius: 2vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    width: 25vw;
    border: .7vh solid #dfb50f
    ;
  }

  .view-more-card {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .card:hover, .view-more-card:hover {
    transform: scale(1.03);
  }

  .card-image {
    width: 100%;
    height: 22vh;
    object-fit: cover;
  }

  .card-content {
    padding: 1vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .card-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }

  .card-description {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.5;
    flex-grow: 1;
  }

  .view-more-btn {
    background-color: white;
    color: #5a378a;
    border: .3vh solid #5a378a;
    padding: 9px 20px;
    border-radius: 2vh;
    cursor: pointer;
    font-size: 1rem;
  }

  @media screen and (max-width: 1024px) {
    .cards-wrapper {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 600px) {
    .cards-wrapper {
      flex-direction: column;
      align-items: center;
    }
    
    .card, .view-more-card {
      width: 100%;
      max-width: 250px;
    }
  }
