/* General Styles */
.sci-header {
    background-color: white;
  }
  
  .sci-container {
    max-width: 98vw;
    margin: 0 auto;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 6rem;
    height: auto;
    overflow: visible;
  }
  .search-bar-sci{
    display: flex;
    flex-direction: row;
    min-width:40vw ;
    justify-content: space-between;
    align-items: center;
  }
  .sci-input-group {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .sci-input {
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
  }
  
  .sci-input:focus {
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
    border: 1px solid #3b82f6;
  }
  
  /* Desktop Navigation */
  .sci-nav {
    display: flex;
    gap: 2rem;
    position: relative;
    justify-content: center;
  }
  
  .sci-nav-link {
    font-size: 1vw;
    text-decoration: none;
    color: #4b5563;
    transition: color 0.3s ease;
  }
  
  .sci-nav-link:hover {
    color: #111827;
  }
  
  .sci-nav-link-active {
    color: #1d4ed8;
    font-weight: bold;
  }
  
  .sci-line {
    position: absolute;
    top: 1.5rem;
    height: 0.1rem;
    background-color: #000;
    transition: all 0.3s ease-in-out;
  }
  .sci-input-group {
    position: relative;
    width: 25vw;
  }
  
  .sci-input {
    width: 100%;
    padding: 12px 40px 12px 16px;
    font-size: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 24px;
    outline: none;
    transition: all 0.3s ease;
    background-color: #ffffff;
  }
  
  .sci-input:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
  }
  
  .sci-input::placeholder {
    color: #9e9e9e;
  }
  
  .search-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239e9e9e'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .sci-input-group.dropdown {
    width: 17vw;
    margin-right: 2vw;
  }

.sci-input {
  width: 100%;
  padding: 12px 40px 12px 16px;
  font-size: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 24px;
  outline: none;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.sci-select {
  width: 100%;
  padding: 12px 40px 12px 16px;
  font-size: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 24px;
  outline: none;
  transition: all 0.3s ease;
  background-color: #ffffff;
  appearance: none;
  cursor: pointer;
}
/* Styling the dropdown list */
.sci-select option {
  font-size: 16px;
  padding: 12px 16px;
  background-color: white;
  color: #333;
  z-index: 10;
}

/* Custom dropdown list container */
.sci-dropdown-list {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
  display: none;
}

.sci-dropdown-list.active {
  display: block;
}

.sci-dropdown-option {
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sci-dropdown-option:hover {
  background-color: #f5f5f5;
}

.sci-dropdown-option:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.sci-dropdown-option:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* Custom scrollbar for the dropdown */
.sci-dropdown-list::-webkit-scrollbar {
  width: 8px;
}

.sci-dropdown-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.sci-dropdown-list::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.sci-dropdown-list::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.sci-input:focus,
.sci-select:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.sci-input::placeholder {
  color: #9e9e9e;
}

.search-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239e9e9e'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dropdown-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239e9e9e'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.sci-input:focus,
.sci-select:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.sci-input::placeholder {
  color: #9e9e9e;
}

.search-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239e9e9e'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dropdown-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239e9e9e'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

  .sci-divider {
    position: absolute;
    top: 1.5rem;
    width: 100%;
    height: 0.1rem;
    background-color: #d1d5db;
  }
  .sci-select-wrapper {
    position: relative;
    width: 100%;
  }
  
  .sci-dropdown-list {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-height: 250px;
    overflow-y: auto;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  
  .sci-dropdown-list.active {
    opacity: 1;
    visibility: visible;
  }
  
  .sci-dropdown-option {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .sci-dropdown-option:hover {
    background-color: #f5f5f5;
  }
  
  /* Ensure the container has proper z-index context */
  .sci-container {
    position: relative;
    z-index: 2;
  }
  
  /* Ensure the header has proper z-index context */
  .sci-header {
    position: relative;
    z-index: 1;
  }
  /* Mobile Navigation */
  .sci-mobile-nav {
    background-color: rgb(155, 155, 155);
    display: flex;
    justify-content: space-between;
    /* padding: .7rem 1rem; */
    width: 70vw;
  }
  
  .sci-mobile-link {
    color: white;
    padding: 1vh 2vw;
    text-decoration: none;
    font-size: .9vh;
    /* margin-right: 3vw; */
    transition: background-color 0.4s ease;
    
    text-align: center;
  }
  
  .sci-mobile-link:hover {
    background-color: #4b5563;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .sci-container {
      padding: 1rem;
    }
  
    .sci-input-group {
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  
    .sci-input {
      width: 100%;
    }
  
    .sci-nav {
      display: none;
    }
    .sci-mobile-nav {
        background-color: rgb(24, 23, 23);
        display: flex;
        justify-content: space-between;
        /* padding: .7rem 1rem; */
        width: 90vw;
      }
  }
  