:root {
    --color-completed: #2ecc71;    
    --color-in-progress: #120621;   
    --color-disabled: #95a5a6;      
    --color-text-light: white;
    --color-text-dark: #120621;
    --color-background: #f4f6f7;
    --color-border: #ecf0f1;
    --transition-speed: 0.3s;
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
@font-face{
    font-family: 'Alegreya';
    src: url('../fonts/alegreya.woff2') format('woff2');
    font-style: normal;
}
@font-face{
    font-family: 'Overpass';
    src: url('../fonts/overpass.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
body {
    font-family: 'Overpass', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--color-background);
    line-height: 1.6;
    color: var(--color-text-dark);
}

/* Progress Buttons Styles */
.progress-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100vw;  
    margin: 0;
    padding: 2rem;
    background-color: white;
    border-radius: 12px;
    min-height: 100vh;
}

.progress-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--color-border);
    padding-bottom: 1rem;
}

.progress-button {
    flex-grow: 1;
    min-width: 120px;
    max-width: 200px;
    margin: 0.25rem;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all var(--transition-speed) ease;
    color: var(--color-text-light);
    font-size: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.progress-button.disabled {
    background-color: var(--color-disabled);
    cursor: not-allowed;
    opacity: 0.6;
}

.progress-button.completed {
    background-color: var(--color-completed);
    cursor: pointer;
}

.progress-button.completed:hover {
    filter: brightness(1.1);
}

.progress-button.in-progress {
    background-color: var(--color-in-progress);
    cursor: pointer;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.progress-button.in-progress:hover {
    filter: brightness(1.1);
}

/* Banner Section Styles */
.banner-section {
    margin-bottom: 2rem;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.banner-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: transform var(--transition-speed) ease;
}

.banner-image:hover {
    transform: scale(1.02);
}

/* Content Sections Styles */
.content-sections {
    display: flex;
    height:auto;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-around;
}

.content-sections section {
    background-color: white;
    padding: 1.5rem;
    border-radius: 12px;
    transition: all var(--transition-speed) ease;
    border: 1px solid var(--color-border);
    margin-top: 10vh;
}


.content-sections h2, 
.content-sections h3 {
    color: var(--color-in-progress);
    border-bottom: 2px solid var(--color-border);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-family: 'Alegreya',sans-serif;
    
}

.content-sections h2 {
    font-size: 1.5rem;
}

.content-sections h3 {
    font-size: 1.25rem;
}

/* Specific Section Styles */
.current-step-details p {
    color: var(--color-text-dark);
    line-height: 1.7;
}

.questionnaire ul {
    list-style-type: none;
    padding: 0;
}

.questionnaire li {
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--color-border);
    transition: background-color var(--transition-speed) ease;
}

.questionnaire li:hover {
    background-color: rgba(52, 152, 219, 0.05);
}

.questionnaire li:last-child {
    border-bottom: none;
}

.abstract {
    font-style: italic;
    color: var(--color-text-dark);
}

.graphical-abstract .graphical-image {
    width: 100%;
    border-radius: 12px;
    transition: transform var(--transition-speed) ease;
}

.graphical-abstract .graphical-image:hover {
    transform: scale(1.02);
}
.form-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 7vh;
    padding-top: 1rem;
    border-top: 1px solid #ddd;
    margin-right: 5vw;
  }
  
  .save-draft-button,
  .submit-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .save-draft-button {
    background-color: #f3f4f6;
    color: #333;
  }
  
  .save-draft-button:hover {
    background-color: #e2e4e6;
    transform: scale(1.02);
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
  }
  
  .submit-button:hover {
    background-color: #45a049;
    transform: scale(1.02);
  }

/* Textarea Styles */
.content-sections textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 1rem;
    color: var(--color-text-dark);
    resize: vertical;
    background-color: var(--color-background); /* Ensures consistent background */
    border: 1px solid var(--color-border);
    border-radius: 8px;
    transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
    box-shadow: var(--box-shadow);
}

.content-sections textarea:focus {
    border-color: var(--color-in-progress);
    background-color: #fff; /* Highlighted background during focus */
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
    outline: none;
}

.content-sections textarea::placeholder {
    color: #bdc3c7;
    font-style: italic;
}
  .graphical-abstract input[type="file"] {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    font-size: 1rem;
    color: var(--color-text-dark);
    background-color: var(--color-background);
    cursor: pointer;
  }
  
  .preview-container {
    margin-top: 15px;
    text-align: center;
  }
  
  .preview-container h3 {
    margin-bottom: 10px;
    color: var(--color-text-dark);
  }
  
  .graphical-abstract-preview {
    max-width: 100%;
    max-height: 300px;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    box-shadow: var(--box-shadow);
  }
  
/* Responsive Design */
@media (max-width: 1200px) {
    .progress-buttons {
        flex-direction: column;
        align-items: stretch;
    }

    .progress-button {
        max-width: none;
        width: 100%;
    }

    .content-sections {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .progress-button {
        font-size: 0.7rem;
        padding: 0.4rem 0.6rem;
    }

    .banner-image {
        height: 250px;
    }
}

/* Form Inputs (if needed) */
.form-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    transition: border-color var(--transition-speed) ease;
}

.form-input:focus {
    outline: none;
    border-color: var(--color-in-progress);
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

/* Utility Classes */
.text-center {
    text-align: center;
}

.mb-2 {
    margin-bottom: 2rem;
}