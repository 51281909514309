
.carousel-container{
     overflow: hidden;
    max-width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background: #f2f2f2;
    margin-top: 10vh;
}
.carousel-container h1{

    font-size: 5vw;
}
.carousel-head{

    font-size: 6vh;
}
.embla {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 40vh;
    
    
  }
  .embla__container {
    display: flex;
    width: 100%; 
  }
  
  .embla__slide {
    flex: 0 0 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .embla-container-adder {
    width: 100%; 
    min-height: 40vh;

  }
  

  .embla__slide {
    flex: 0 0 100%;
    
  }
.embla-slide-adder{

    margin-left: 5vw;
    border-radius: 2vh;
}
.carousels{
    display: flex;
    width: 60vw;
    height:70vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background: white; */
    border-radius: 3vh;
    /* overflow: hidden; */
}

.carousels img{

    width:24vw;
    height:24vw;
    margin: 2vw;

  }
.contents-carousel{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:33vw;
    height:auto;
  }
.contents-carousel .carousel-content-head{

    font-size: 3vh;
    font-weight: 500;
    margin-bottom: 2vh;
}
.action-bt-car{

    background: transparent;
    width:3vw;
    height: 3vw;
    border-radius: 50%;
}
.action-bt-car img{

    margin-left: 0;

}
#next, #prev {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    z-index: 1;
}

#next {
    right: 10vw;
}

#prev {
    left: 10vw;
}
.carousel-content-head {
    font-size: 2.5vh;
    text-align: center;
    margin-bottom: 1vh;
  }
  
  .video-wrapper {
    position: relative;
    width: 60vw;
    padding-top: 26.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
    background: #ddd;
    border-radius: 8px;
  }
  
  .video-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 30%;
    border: none;
  }
  
  .video-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 1.2em;
    color: #555;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 1vh;
  }

  .contents-carousel {
    width: 20vw;
    padding: 2vh;
    height: 24vw;
  }

  .carousel-content-head {
    font-size: 2vh;
  }

  .video-placeholder {
    font-size: 1em;
  }

.btn {
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    position: relative;
    background-color: transparent;
    transition: .2s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0.6;
  }
  
  .btn::after {
    content: '';
    border-bottom: 3px double rgb(214, 207, 113);
    width: 0;
    height: 100%;
    position: absolute;
    margin-top: -5px;
    top: 0;
    left: 5px;
    visibility: hidden;
    opacity: 1;
    transition: .2s linear;
  }
  
  .btn .icon {
    transform: translateX(0%);
    transition: .2s linear;
    animation: attention 1.2s linear infinite;
  }
  
  .btn:hover::after {
    visibility: visible;
    opacity: 0.7;
    width: 90%;
  }
  
  .btn:hover {
    letter-spacing: 2px;
    opacity: 1;
  }
  
  .btn:hover > .icon {
    transform: translateX(30%);
    animation: none;
  }
  
  @keyframes attention {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(30%);
    }
  }

  @media screen and (min-width: 1024px){
    .carousel-head{
        font-size: 3.5vh;
    }
    .embla-container-adder{

        width: 95vw;
        padding: 5vw;
        min-height: 50vh;
        margin-right: 6vw;
        
    }
    .carousels{
        display: flex;
        width: 80vw;
        height:30vh;
        margin: 3vw;
        
    }
    .contents-carousel{

        display: flex;
        flex-direction: column;
        width:40vw;
      }
      #next{

        position: absolute;
        top:60%;
        right:8vw;
        z-index: 1;
    }
    #prev{
    
        position: absolute;
        top:60%;
        left:3vw;
        z-index: 1;
    }
    .contents-carousel {
        width: 80vw;
        padding: 2vh;
      }
    
      .carousel-content-head {
        font-size: 2vh;
      }
    
      .video-placeholder {
        font-size: 1em;
      }
     .action-bt-car img{
        width:2.5vw;
        height: 2.5vw;
     }
  }

@media screen and (max-width: 1024px) {
    #next {
        right: 3vw;
    }
    #prev {
        left: 3vw;
    }
}
@media screen and (max-width: 1024px) {

    .carousel-head{
        font-size: 3.5vh;
    }
    .embla-container-adder{

        width: 95vw;
        padding: 5vw;
        min-height: 50vh;
        
    }
    .carousels{
        display: flex;
        width: 80vw;
        height:30vh;
        margin: 3vw;
        
    }
    .contents-carousel{

        display: flex;
        flex-direction: column;
        width:50vw;
      }
      #next{

        position: absolute;
        top:60%;
        right:8vw;
        z-index: 1;
    }
    #prev{
    
        position: absolute;
        top:60%;
        left:3vw;
        z-index: 1;
    }
    .contents-carousel {
        width: 80vw;
        padding: 2vh;
      }
    
      .carousel-content-head {
        font-size: 2vh;
      }
    
      .video-placeholder {
        font-size: 1em;
      }

}
@media (min-width: 768px) and (max-width: 1023px) {
    #next {
        right: 3vw;
    }
    #prev {
        left: 3vw;
    }
    .contents-carousel {
        width: 80vw;
        padding: 2vh;
      }
    
      .carousel-content-head {
        font-size: 2vh;
      }
    
      .video-placeholder {
        font-size: 1em;
      }
      .video-wrapper {
        position: relative;
        width: 75vw;
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
      }
      .btn{
        margin-top: 5vw;
      }
}
@media (max-width: 768px) {
    #next {
        right: 3vw;
        top: 50%;
        margin-right: 2vh;
    }
    #prev {
        left: 3vw;
        top: 50%;
    }
    
    .carousel-container h1{
      margin-top: 4vh;
      font-size: 7vw;
  }
      .contents-carousel {
        width: 90vw;
      }
    
      .carousel-content-head {
        font-size: 1.8vh;
      }
    
      .video-placeholder {
        font-size: 0.9em;
      }
      .video-wrapper {
        position: relative;
        width: 75vw;
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
      }    
}
  
  @media (max-width: 480px) {
    .contents-carousel {
      width: 90vw;
    }
  
    .carousel-content-head {
      font-size: 1.8vh;
    }
  
    .video-placeholder {
      font-size: 0.9em;
    }
    #next,#prev{
      top:40%;
    }
  }