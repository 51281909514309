@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
}