:root {
  --yellow-gold: #a18c36;
  --dark-violet: #120621;
  --lighter-violet: #200f36;
  --white: #f3f3f3;
}
*{
    font-family: "Inter", sans-serif;
}



  /* Desktop Styles (1024px and above) */
  @media (min-width: 1024px) {
    

  .sidebar-container{
      display: flex;
      flex-direction: column;
      width: 30%;
      height: 100%;
      align-items: flex-start;
      margin-left: 1vw;
      margin-top: 4vh;
      background: #f3f3f3;
  }
  .sidebar-links-container{
  
      display: flex;
      flex-direction: column;
      width: 25vw;
      height: 100%;
      justify-content: space-around;
  
  
  }
  .privacy-links{
      text-decoration: none;
      font-size: 2.5vh;
      width: 43%;
      color: #121112;
      margin: 1.5vh;
      text-align:left;
      padding: 1vh 2vw;
      border-radius: 1vh;
      transition: .3s;
      width: fit-content;
  }
  .privacy-links:hover{
      color: var(--white);
      background: #200f36;
  }
  #sidebar-active{
      display: none;
      width: 30vh;
  }
  
  .burger-menu-icon, .X-menu-icon{
      display: none;
  }
  .sidebar-container label svg{
      display: none;
  }


  }
  
  /* Tablet Styles (768px to 1023px) */
  @media (max-width: 1023px) and (min-width: 768px) {
    .sidebar-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        margin-left: 1vw;
        background: #f3f3f3;
        min-height: 6vh;
        background-color: rgb(239, 239, 239);
        margin-bottom: 2vh;
    }
    .sidebar-links-container{
    
        display: none;
        flex-direction: column;
        width: 75vw;
        height: 100%;
        position: fixed;
        z-index: 10;
        left:0;
        margin-top: -27vh;
        background-color: var(--dark-violet);
      padding-top: 25vh;
      padding-right: 10vw;
      transition: 1s ease-out;
    }
    .privacy-links{
        text-decoration: none;
        font-size: 2.5vh;
        width: 93%;
        color: var(--white);
        margin: 1.5vh;
        text-align:right;
        padding: 1vh 2vw;
        border-radius: 1vh;
        transition: .3s;
    }
    .privacy-links:hover{
        color: var(--white);
        background: #200f36;
    }
    #sidebar-active{
        display: none;
    }
    
    .burger-menu-icon{
      display: block;
      width: 5vh;
      height: 5vh;
    }
  
  
    .sidebar-links-container label svg{
        width: 5vh;
        height: 5vh;
        text-align: right;
        align-self:flex-start;
        color: var(--white);
        margin-bottom: 10vh;
        margin-top: -16vh;
        margin-left: 60vw;
    }
  
    #sidebar-active:checked ~ .sidebar-links-container{
      display: flex;
  
    } 
  
  }
  
  /* Mobile Styles (Below 768px) */
  @media (max-width: 767px) {

  .sidebar-container{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: flex-start;
      margin-left: 1vw;
      background: #f3f3f3;
      min-height: 6vh;
      background-color: rgb(239, 239, 239);
      margin-bottom: 2vh;
  }
  .sidebar-links-container{
  
      display: none;
      flex-direction: column;
      width: 75vw;
      height: 100%;
      position: fixed;
      z-index: 10;
      left:0;
      margin-top: -27vh;
      background-color: var(--dark-violet);
    padding-top: 25vh;
    padding-right: 10vw;
    transition: 1s ease-out;
  }
  .privacy-links{
      text-decoration: none;
      font-size: 2.5vh;
      width: 93%;
      color: var(--white);
      margin: 1.5vh;
      text-align:right;
      padding: 1vh 2vw;
      border-radius: 1vh;
      transition: .3s;
  }
  .privacy-links:hover{
      color: var(--white);
      background: #200f36;
  }
  #sidebar-active{
      display: none;
  }
  
  .burger-menu-icon{
    display: block;
    width: 5vh;
    height: 5vh;
  }


  .sidebar-links-container label svg{
      width: 5vh;
      height: 5vh;
      text-align: right;
      align-self:flex-start;
      color: var(--white);
      margin-bottom: 10vh;
      margin-top: -16vh;
      margin-left: 60vw;
  }

  #sidebar-active:checked ~ .sidebar-links-container{
    display: flex;

  } 


  }