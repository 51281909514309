

@font-face{
    font-family: 'Alegreya';
    src: url('../fonts/alegreya.woff2') format('woff2');
    font-style: normal;
}
@font-face{
    font-family: 'Overpass';
    src: url('../fonts/overpass.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
.acceptance-main-container {
    padding: 2rem;
    border-radius: 12px;
    max-width: 100VW;
}

.acceptance-main-container h1,
.acceptance-main-container h2 {
    color: var(--color-in-progress);
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Alegreya';
}

.acceptance-main-container h2 {
    font-size: 1.25rem;
}

/* Input Fields and Textareas */
.acceptance-main-container input,
.acceptance-main-container textarea {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 1rem;
    color: var(--color-text-dark);
    resize: vertical;
    background-color: var(--color-background); 
    border: 1px solid var(--color-border);
    border-radius: 8px;
    transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
    box-shadow: var(--box-shadow);
    font-family: 'Overpass';
}

.acceptance-main-container input:focus,
.acceptance-main-container textarea:focus {
    border-color: var(--color-in-progress);
    background-color: #fff;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
    outline: none;
}

.acceptance-main-container textarea {
    resize: vertical;
    height: 150px;
}

.acceptance-main-container input::placeholder,
.acceptance-main-container textarea::placeholder {
    color: #bdc3c7;
    font-style: italic;
}

/* File Upload Section */
.upload-preview {
    display: flex;
    flex-direction: column;
}

.upload-preview input[type="file"] {
    padding: 5px;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    font-size: 1rem;
    color: var(--color-text-dark);
    background-color: var(--color-background);
    margin-bottom: 1rem;
}

.preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.preview img {
    width: 100px;
    height: auto;
    border-radius: 8px;
    margin-right: 1rem;
}

.preview button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.preview button:hover {
    background-color: #c0392b;
}

/* Results Section */
.results-section {
    margin-top: 2rem;
}

.results-section .result-contents-section {
    display: flex;
    gap: 2rem;
}

.results-section .left-fields,
.results-section .right-fields {
    flex: 1;
}

.results-section .left-fields input,
.results-section .left-fields textarea {
    margin-bottom: 1rem;
}

.results-section .right-fields textarea {
    margin-bottom: 1rem;
}

/* Link Section */
.linker-section input {
    margin-top: 1rem;
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    width: 100%;
}

/* Word Count for Abstract */
.word-count {
    color: var(--color-text-dark);
    font-size: 0.9rem;
    text-align: right;
}
.acceptance-main-container section {
    background-color: white;
    padding: 1.5rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid #ddd; 
    margin-top: 10vh;
}

.acceptance-main-container section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.acceptance-main-container h2, 
.acceptance-main-container h3 {
    color: #120621; 
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
}

.acceptance-main-container h2 {
    font-size: 1.5rem;
}

.acceptance-main-container h3 {
    font-size: 1.25rem;
}

/* Textarea Styles */
.acceptance-main-container textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 1rem;
    color: #333; 
    resize: vertical;
    background-color: #f9f9f9; 
    border: 1px solid #ddd; 
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.acceptance-main-container textarea:focus {
    border-color: #3498db; 
    background-color: #fff; 
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
    outline: none;
}

.acceptance-main-container textarea::placeholder {
    color: #bdc3c7;
    font-style: italic;
}

/* File Input Styles */
.acceptance-main-container input[type="file"] {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #ddd; /* Replace var(--color-border) with the desired color */
    border-radius: 8px;
    font-size: 1rem;
    color: #333; 
    background-color: #f9f9f9;
    cursor: pointer;
}
