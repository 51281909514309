
.sci-home-page{

    display: flex;
    flex-direction: column;
    align-items: center;
}
.intro-section{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95vw;
    margin: 5vh 3vw;

}
.sci-description {
    display: flex
;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 45vw;
    min-height: 39vh;
    padding: 3rem;
    color: white;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    margin-right: 3vw;
}
.right-shape{
  margin: 0;
  padding: 0;
  max-width: 40vw;
}
.sci-description .heading{
    font-size: 2.2vw;
}
.book-of-month{
    display: flex;
    width: 95vw;
    justify-content: space-around;
    align-items: center;
    margin-top: 12vh;
}
.shape-sci-book{
    display: flex;
    flex-direction: column;
    width: 45vw;
}
.add-to-lib{
    width: 76%;
    height:6vh;
    border-radius: 2vh;
    background: #5a378a;
    color: beige;
    margin-top: 2vh;
    margin-left: 2vw;

}
.question-of-the-day {
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 20px;
    margin-top: 20vh;
  }
  
  .q-heading {
    font-size: 7vh;
    color: #4c2c84; /* Purple color */
    font-weight: bold;
    position: relative;
    display: inline-block;
    margin: 0 auto 20px auto;
  }
  
.q-heading:before,
.q-heading:after {
    content: '';
    display: inline-block;
    width: 10vw;
    height: .6vh;
    background-color: #4c2c84;
    position: relative;
    top: -10px;
  }
  
 .q-heading:before {
    margin-right: 10px;
  }
  
   .q-heading:after {
    margin-left: 10px;
  }
  
  .content {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .left-box,
  .right-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    
    height:40vh;
  }
  
  .left-box {
    background-color: #e6e0f8; /* Light purple */
    color: #4c2c84;
    width: 50vw;
  }
  
  .right-box {
    background-color: #ffe0d0; /* Light orange */
    color: #000;
    text-align: center;
    font-weight: bold;
    width: 30vw;
  }

  .p-topic-container {
    max-width: 95vw;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 12vh;
  }

  .p-topic-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #44337a;
    text-align: center;
    margin-bottom: 2rem;
  }

  .p-topic-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5vw;
    padding: 1rem;
  }

  .p-topic-card-wrapper {
    position: relative;
  }

  .p-topic-card {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 100%;
  }

  .p-topic-image-container {
    position: relative;
    height: 200px;
  }

  .p-topic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .p-topic-overlay {
    position: absolute;
    inset: 0;
    background-color: #44337a;
  }

  .p-topic-content {
    padding: 1rem;
  }

  .p-topic-heading {
    font-size: 0.875rem;
    font-weight: 600;
    color: #44337a;
    text-align: center;
    margin: 0;
  }

  @media (max-width: 768px) {
    .p-topic-grid {
      grid-template-columns: 1fr;
    }

    .p-topic-container {
      padding: 1rem;
    }
  }
  