@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .form-header #contact-head{

    font-family: "Inter", sans-serif;
}
.submit-btn-contact:hover{
  background: #2d2d2d;
  transition: background 0.3s ease, transform 0.3s ease;
}
.submit-btn-contact:active{
  background: #2d2d2d;
  transition: background 0.3s ease, transform 0.3s ease;
  transform: scale(1.08);
}

.input-component{

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 2vh 3vw;
}
.gradient-banner .banner-image{
  transition: transform 0.3s ease, transform 1s ease;
  
}
.gradient-banner .banner-image:hover{

  transform: rotate(-5deg) scale(1.02);
  transition: transform 0.3s ease, transform .5s ease;
  filter: opacity(.8);
  
  


}
  /* Desktop Styles (1024px and above) */
  @media (min-width: 1024px) {
    .scitome-contact-us-container{
      display: flex;
      width: 90vw;
      margin: 0vh;
      margin-left: 5vw;
      background: #f3f3f3;
      min-width: 95vw;
      min-height: 60vh;
      height: 100vh;
      

  }
  .scitome-form-container{

    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    justify-content: space-around;
    margin-top: 0vh;
    min-height: 60vh;
    height: 90vh;
  }
  .form-header{

    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;

  
  }
  .form-header #contact-head{

    font-size: 10vh;
    font-family: "Inter", sans-serif;
    margin-bottom: 2vh;
  }
  .form-header .contact-desc{

    font-size: 3vh;
    font-family: "Inter", sans-serif;
    color: #a9a8a8;      
      
  }
  .input-component{
    min-width: 23vw;
    
  }
.input-box-contact{

    width: 24vw;
    height: 5vh;
    border-radius: 1vh;
    border: 1px solid #d7d6d6;
    font-size: 2.5vh;
    padding: .3vh 5vw .1vh 5vw;
    outline-color: #c2c0c0;
    margin-bottom: 2vh;
  }
  .input-box-contact::placeholder{

      color: #c2c0c0;
  }
  .input-label{
    color: rgb(23, 22, 22);
    font-size: 2.3vh;
    font-weight: 600;
    margin: 0;
    
    align-self: flex-start;
  }
  
  
  #message{
    
    width: 54vw;
    height: 17vh;
    border-radius: 1vh;
    border: 1px solid #d7d6d6;
    font-size: 2vh;
    padding: .3vh 5vw .1vh 5vw;
    outline-color: #c2c0c0;
    padding: 2vh;
  }
  .contact-form{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 40vh;
    height: 80vh;
  }
  .names{
    display: flex;
    flex-direction: row;
    height: 31vh;
  }
  .contact-form > .input-component .input-box-contact{

    width: 54vw;
    height: 6vh;
    border-radius: 1vh;
    border: 1px solid #d7d6d6;
    font-size: 2.5vh;
    padding: .3vh 5vw .1vh 5vw;
    outline-color: #c2c0c0;
    margin-bottom: 2vh;
  }
  
  .submit-btn-contact{
      width: 54vw;
      height: 9vh;
      padding-top: 2vh;
      padding-bottom: 2vh;
      color: #fffbfb;
      background: #151515;
      border: none;
      border-radius: .5vh;
      margin-top: 1vh;
      font-size: 2.5vh;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      margin-left: 3vw;
  }
  .gradient-banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 2vh;
    height: 90vh;
    width:50vw;
    aspect-ratio: 6/19;
    /* margin-top: 20vh; */
    /* margin-bottom: 10vh; */
  }
  .gradient-banner .banner-image{

    height: 65vh;
    width: 23vw;
    border-radius: 2.5vh;
    /* margin-bottom: 10vh; */
    
  }
  }
  
  /* Tablet Styles (768px to 1023px) */
  @media (max-width: 1023px) and (min-width: 768px) {
    .scitome-contact-us-container{
      display: flex;
      flex-direction: column;
      min-width: 95vw;
      min-height: 70vh;
      margin: .5vh;
      align-items: center;
      background: #f3f3f3;
      height: auto;
  }
  .scitome-form-container{

      display: flex;
      flex-direction: column;
      background: #f3f3f3;
      justify-content: space-around;
      margin-top: 0vh;
      min-height: 60vh;
      height: 87vh;
  }
  .form-header{

      display: flex;
      flex-direction: column;
  }
  .form-header #contact-head{

      font-size: 6vh;
      font-family: "Inter", sans-serif;
      margin-bottom: 1vh;
  }
  .form-header .contact-desc{

      font-size: 2vh;
      font-family: "Inter", sans-serif;
      color: #a9a8a8;
      
  }

  .input-box-contact{

      width: 90vw;
      height: 6vh;
      border-radius: 1vh;
      border: 1px solid #d7d6d6;
      font-size: 2vh;
      padding: .3vh 5vw .1vh 5vw;
      outline-color: #c2c0c0;
      margin-bottom: 2vh;
  }
  .input-box-contact::placeholder{

      color: #c2c0c0;
  }
  .input-label{

      color: rgb(23, 22, 22);
      font-size: 1.7vh;
      font-weight: 700;
      margin: 0;
      align-self: flex-start;
  }
  #message{
      width: 90vw;
      height: 10vh;
      border-radius: 1vh;
      border: 1px solid #d7d6d6;
      font-size: 2vh;
      padding: .3vh 5vw .1vh 5vw;
      outline-color: #c2c0c0;
      margin: 1vh;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      padding: 4vh;

  }
  .contact-form{
      display: flex;
      flex-direction: column;
      height: 68vh;
      align-items: center;
  }
  .names{
      display: flex;
      flex-direction: column;
      height: 25vh;
  }
  .submit-btn-contact{
      width: 90vw;
      height: 7vh;
      padding-top: 2vh;
    padding-bottom: 2vh;
      color: #fffbfb;
      background: #151515;
      border: none;
      border-radius: .5vh;
      margin-top: 1vh;
      font-size: 2.5vh;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }

  .gradient-banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2vh;
    height: 25vh;
    width:50vw;
    aspect-ratio: 6/19;
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
  .gradient-banner .banner-image{

    height: 45vh;
    width:90vw;
    border-radius: 2.5vh;
    margin-bottom: 10vh;
    
  }

  }
  
  /* Mobile Styles (Below 768px) */
  @media (max-width: 767px) {
    .scitome-contact-us-container{
        display: flex;
        flex-direction: column;
        min-width: 95vw;
        min-height: 70vh;
        margin: .5vh;
        align-items: center;
        background: #f3f3f3;
        height: auto;
    }
    .scitome-form-container{

        display: flex;
        flex-direction: column;
        background: #f3f3f3;
        justify-content: space-around;
        margin-top: 0vh;
        min-height: 60vh;
        height: 87vh;
    }
    .form-header{

        display: flex;
        flex-direction: column;
    }
    .form-header #contact-head{

        font-size: 6vh;
        font-family: "Inter", sans-serif;
        margin-bottom: 1vh;
    }
    .form-header .contact-desc{

        font-size: 2vh;
        font-family: "Inter", sans-serif;
        color: #a9a8a8;
        
    }

    .input-box-contact{

        width: 90vw;
        height: 6vh;
        border-radius: 1vh;
        border: 1px solid #d7d6d6;
        font-size: 2vh;
        padding: .3vh 5vw .1vh 5vw;
        outline-color: #c2c0c0;
        margin-bottom: 2vh;
    }
    .input-box-contact::placeholder{

        color: #c2c0c0;
    }
    .input-label{

        color: rgb(23, 22, 22);
        font-size: 1.7vh;
        font-weight: 700;
        margin: 0;
        align-self: flex-start;
    }
    #message{
        width: 90vw;
        height: 10vh;
        border-radius: 1vh;
        border: 1px solid #d7d6d6;
        font-size: 2vh;
        padding: .3vh 5vw .1vh 5vw;
        outline-color: #c2c0c0;
        margin: 1vh;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        padding: 4vh;

    }
    .contact-form{
        display: flex;
        flex-direction: column;
        height: 68vh;
        align-items: center;
    }
    .names{
        display: flex;
        flex-direction: column;
        height: 25vh;
    }
    .submit-btn-contact{
        width: 90vw;
        height: 7vh;
        padding-top: 2vh;
      padding-bottom: 2vh;
        color: #fffbfb;
        background: #151515;
        border: none;
        border-radius: .5vh;
        margin-top: 1vh;
        font-size: 2.5vh;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }

    .gradient-banner{
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 2vh;
      height: 25vh;
      width:50vw;
      aspect-ratio: 6/19;
      margin-top: 5vh;
      margin-bottom: 10vh;
    }
    .gradient-banner .banner-image{

      height: 45vh;
      width:90vw;
      border-radius: 2.5vh;
      margin-bottom: 10vh;
      
    }
    
  }
  