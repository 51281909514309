
.modal {
    display: flex;
    flex-direction: column;
    background: white;
    width: 90%; 
    max-width: 400px; 
    margin: 20px auto; 
    padding: 20px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    transition: opacity 0.3s ease, transform 0.3s ease;
    
    
  }
  .modal-signup-container{
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%; 
    
    margin: 20px auto; 
    padding: 20px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  }
  .phone-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .select-dropdown.country-code {
    width: 120px; /* Adjust width as needed */
    flex-shrink: 0;
  }
  
  .phone-number-input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  
  
  
  .signupmodal{
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%; 
    
    /* margin: 20px auto;  */
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 8px;
    
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .signupmodal .group{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    margin: 7vw;

  }
  .overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .social-auth {
    margin-top: 20px;
    text-align: center;
  }
  
  .social-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding: 10px;
    padding-right: 46%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  
  .social-btn.google {
    background-color: #eeeeee;
    color: #000000;
    font-size: 2vh;
  }
  
  .social-btn.facebook {
    background-color: #4267b2;
    color: white;
  }
  
  .switch-auth {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
  }
 
  .divider-text {
    color: #6f6c6c;
    font-size: 2vh;
    text-align: center; /* Center the text and the lines */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h2{

    align-self: center;
    
  }
  .text-desc{
    font-size: 1.7vh;
    align-self: center;
  }
  .divider-text::before,
  .divider-text::after {
    content: "";
    flex: 1; 
    width: 20vw;
    height: 1px;
    background: #6f6c6c;
  }
  
  .divider-text::before {
    margin-right: 10px;
  }
  
  .divider-text::after {
    margin-left: 10px;
  }
  .switch-auth span {
    color: #007bff;
    cursor: pointer;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
 
.select-dropdown {
  width: 100%;
  /* max-width: 400px;  */
  padding: 10px 15px; 
  border: 1px solid #ccc;
  border-radius: 5px; 
  font-size: 16px; 
  color: #333; 
  background-color: #fff;
  appearance: none; 
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23333" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M4.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"/%3E%3C/svg%3E');
  background-repeat: no-repeat; 
  background-position: right 15px center; 
  background-size: 12px; 
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


.select-dropdown:focus {
  outline: none; 
  border-color: #007bff; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}


.select-dropdown option {
  padding: 10px;
}

.required {
  color: red;
  margin-left: 5px;
}


  @media (min-width: 768px) {
    .modal {
      width: 50%;
      max-width: 500px; 
    }
  
    .submit-btn, .social-btn {
      font-size: 18px; 
    }
    .modal-signup-container h2{

      font-size: 9vh;
    }
    .modal-signup-container .text-desc{

      font-size: 2.5vh;
    }
    .modal-signup-container .submit-btn{

      width: 60%;
      align-self: center;
    }
  }
  
  
  @media (max-width: 767px) {
    .modal {
      width: 95%; 
      margin: 10px auto;
    }
  
    .submit-btn {
      font-size: 14px; 
      padding: 10px;
    }
  
    .social-btn {
      font-size: 12px; 
      width: 100%; 
      margin: 5px 0;
    }
    .signupmodal{
      flex-direction: column;
    }
    .select-dropdown {
      font-size: 14px; 
    }
  }
  