* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Header container */
.header {
    width: 100%;
    background-color: white;
    position: relative;
}

.header-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 1rem;
}

.nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15vh;
}

/* Logo styles */
.logo-section {
    display: flex;
    align-items: center;
    padding: 1vh;
}
.logo-section  img{

    width: 12.16vw;
    height: 6.6vw;
    margin-top: 0vh;
    margin-left: 1vw;
    cursor: pointer;
}
.logo-nav-container {
    display: flex;
    justify-content: space-between;
    height: 10vh;
    min-width: 10vw;
    
    
}
  
.logo-nav {
    height: 32px;
    width: 32px;
    position: relative;
    color: #2D1842; /* Dark purple */
}

.logo-nav img{
    width:  12vw;
    height: 12vw;
    margin-top: -1vh;
}

.logo-nav-text{

    display: flex;
    flex-direction: column;
    margin-left: 3vw;
}
.company-name {
    margin-left: 8px;
    font-size: 1.25rem;
    font-weight: bold;
    color: #2D1842;
}

.company-subtitle {
    margin-left: 8px;
    font-size: 0.875rem;
    color: #666;
    
}

/* Navigation */
.nav-menu {
    display: none;
}

.nav-menu a {
    color: #6B46C1;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.nav-menu a:hover {
    color: #553C9A;
}

.login-btn {
    background-color: #2D1842;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #3D2A5A;
}

/* Mobile menu button */
.mobile-menu-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.mobile-menu-btn span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #2D1842;
    transition: all 0.3s ease;
}

/* Mobile navigation */
.mobile-nav {
    display: none;
    padding: 1rem;
    background-color: white;
}

.mobile-nav a {
    display: block;
    padding: 0.75rem 1rem;
    color: #6B46C1;
    text-decoration: none;
}

.mobile-nav .login-btn {
    width: 100%;
    margin-top: 0.5rem;
}

/* Show mobile menu when active */
.mobile-nav.active {
    display: block;
}

/* Hamburger menu animation states */
.mobile-menu-btn.active span:first-child {
    transform: rotate(45deg) translate(5px, 5px);
}

.mobile-menu-btn.active span:nth-child(2) {
    opacity: 0;
}

.mobile-menu-btn.active span:last-child {
    transform: rotate(-45deg) translate(5px, -5px);
}

  


/* Desktop styles */
@media (min-width: 768px) {
    .mobile-menu-btn {
        display: none;
    }

    .nav-menu {
        display: flex;
        align-items: center;
        gap: 2rem;
        min-width: 40vw;
    }

    .company-subtitle {
        display: inline;
    }

    .mobile-nav {
        display: none !important;
    }
    .logo-nav-container {
        display: flex;
        justify-content: space-between;
        height: 12vh;
        min-width: 10vw;
        align-items: center;
        
        
    }
    .logo-nav img{
        width: 5vw;
        height: 5vw;
        margin-top: -1vh;
        object-fit: contain;
        margin-left: 1vw;
    }
}